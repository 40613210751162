import React from "react";
import styled from "styled-components";
import {telegramUrl} from "../addon/utils";

export const Header = () => {


    return <React.Fragment>
        <VideoDiv>
            <video id="bannervideo" className="hidden-xss" poster="/images/background/transparent.png"
                   autoPlay={true} muted={true} loop={true} preload="">
                <source src="/videos/video_background_two.mp4" type="video/mp4"/>
                <img src="/images/background/bg.jpg"
                     alt="The Sugar Daddy &amp; Sugar Baby Dating: Fastest Growing Network for daddies and babies."/>
                <p>Your browser does not support the video tag.</p>
            </video>
            <div id="bannervideo_title" className="title hidden-xss" style={{color: "#fff"}}>
                <h1 className="h1-title">Where Romance Meets Finance</h1>
                <p id="desc">Build and negotiate your terms of relationships</p>
                <p style={{marginTop:"25px"}}><a href={telegramUrl} target={"_blank"} rel="noreferrer" className="btn btn-login">Join Today</a></p>
            </div>
        </VideoDiv>
    </React.Fragment>
}


const VideoDiv = styled.div`
    box-sizing: border-box;
    width: 100%;
    overflow: hidden;
    color: #fff;
    position: relative;
    height: 100svh;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
    
    video {
        background: transparent url(/images/background/bg.jpg) no-repeat 30% 0;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        object-fit: cover;
        object-position: 50% 40%;
        width: 100%;
        height: 100%;
        display: inline-block;
        vertical-align: baseline;
    }
    
    #desc, .h1-title{
        text-align: center;
    }
    
    .h1-title{
        font-size: 32px;
        margin-top: 20px;
        margin-bottom: 10px;
        font-weight: 100;
    }
    
    #desc{
        font-size: 14px;
        font-weight: 300;
        padding-left: 5px;
        letter-spacing: 0.8px;
        line-height: 1.42857143;
        margin: 0 auto;
    }
    
    #bannervideo_title{
        margin: 0 auto;
        position: absolute;
        z-index: 2;
        top: 66%;
        transform: translateY(-50%);
        width: 100%;
        text-align: center;
        color: #fff;
    }
    
    a.btn-login {
        background-color: #710d0d;
        background-repeat: repeat-x;
        color: #fff!important;
        text-shadow: 0 -1px 0 transparent;
        -webkit-font-smoothing: antialiased;
        font-weight: 700;
        border-radius: 50px!important;
        padding-left: 20px!important;
        padding-right: 20px!important;
        text-decoration: none;
    }
    
    @media screen and (max-width: 768px) {
        height: 100svh;
        background: transparent url(/images/background/bg.jpg) no-repeat 30% 0;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        //background-position: center;

        video{
            display: none !important;
        }

        .h1-title{
            padding-left: 15px;
            padding-right: 15px;
        }
    }
    
`