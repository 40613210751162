import React, {useEffect, useRef, useState} from 'react';
import {Container} from "react-bootstrap";
import styled, {createGlobalStyle} from "styled-components";
import {domainName, phoneNumber, siteTitle, telegramUrl, whatsappUrl} from "../addon/utils";
const rec: DOMRect = {height: 0, top: 0, bottom: 0, width: 0, y: 0, x: 0, left: 0, right: 0} as any

export const Content = () => {
    const row = useRef<HTMLDivElement>(null)
    const [outerHeight, setOuterHeight] = useState<DOMRect>(rec)

    useEffect(() => {
        setOuterHeight(row.current?.getBoundingClientRect() ?? rec)
        window.addEventListener("resize", function () {
            setOuterHeight(row.current?.getBoundingClientRect() ?? rec)
            console.log(row.current?.getBoundingClientRect() ?? rec)
        })
        console.log(row.current?.getBoundingClientRect() ?? rec)
        }, [])


        return <React.Fragment>
            <GlobalCss/>
            <div style={{height: 150, minHeight: 150, background: '#710D0D'}}></div>
            <BoardDiv>
                <SugarMummiesDiv $childHeight={outerHeight}>
                    <BoxContainer ref={row}>

                        <h1>Contact</h1>
                        <p>If you have feedback, comments or questions please contact us at:-</p>
                        <br/>

                        <p><b>General Inquiry:</b> <a href={`mailto:hello@${domainName}`}>hello@{domainName}</a></p>
                        <p><b>Support:</b> <a href={`mailto:support@${domainName}`}>support@{domainName}</a></p>
                        <p><b>WhatsApp:</b> <a href={whatsappUrl}><b>Mrs. Anna</b> {phoneNumber}</a></p>
                        <p><b>Telegram:</b> <a href={telegramUrl}>Connect with with <b>Miss Alice</b></a></p>
                        <br/>
                        <h3 style={{marginBottom: 0}}>{siteTitle}.</h3>
                        <small style={{display: "block", marginBottom: 10}}>(HE395258)</small>
                        <p>Petronas Twin Tower,</p>
                        <p>Lower Ground (Concourse) Level, Kuala Lumpur City Centre</p>
                        <p>50088 Kuala Lumpur, Malaysia</p>

                    </BoxContainer>
                </SugarMummiesDiv>


            </BoardDiv>
        </React.Fragment>
    }


    const SugarMummiesDiv = styled(Container)<{ $paddingTop?: number; $paddingBottom?: number, $childHeight: DOMRect }>`
        padding: ${props => props.$paddingTop || 70}px 0 ${props => props.$paddingBottom || 70}px;
        position: relative;
        height: ${props =>( props.$childHeight.height - parseFloat(props.$childHeight.top.toString().replace("-", ""))) + 100}px;


        @media screen and (min-width: 1200px) {
            max-width: 1170px;
        }

    `


    const GlobalCss = createGlobalStyle`
        @media screen and (max-width: 768px) {
            body {
                padding-top: 80px;
            }
        }

        .btn-border:hover {
            border-color: #989898 #989898 #999 !important;
        }

        .btn-border-none:focus {
            outline: none !important
        }
    `

    const BoardDiv = styled.div`
        width: 100%;
        @media screen and (max-width: 768px) {
            padding: 0 15px;
        }
    `

    const BoxContainer = styled.div`
        font-family: 'Source Sans Pro';
        font-weight: 400;
        font-size: 17px;
        box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
        background-color: #fff;
        width: 75%;
        padding: 50px 20px 50px;
        position: absolute;
        top: -85px;
        left: 12.5%;
        min-height: 100px;
        z-index: 10;

        h1 {
            font-size: 36px;
            margin-top: 20px;
            margin-bottom: 10px;
        }

        h3 {
            margin-bottom: 0;
            font-size: 24px;
        }

        a {
            color: #710D0D;
            text-decoration: none;
        }

        a:hover, a:focus {
            text-decoration: underline;
        }

        @media screen and (max-width: 768px){
            left: 0 !important;
            width: 100% !important;
        }
    `

