export const siteTitle: string = "SugarMummyMalaysia.com";
export const siteName: string = "SugarMummyMalaysia.com";
export const phoneNumber: string = '+60 11 2621 8522' //+60 11 2621 8522
export const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber.replaceAll(" ", "")}&text=%F0%9F%99%8B%E2%80%8D%E2%99%82%EF%B8%8F%20Hi`
export const telegramUrl = `https://t.me/Alicemalayconnect`
export const domainName:string = 'sugarmummymalaysia.com'
/*
WHAT IT MEANS TO BE A Sugar Daddy
 */
export const W_I_M_T_B_A_S_D: { text: string, title: string, image?: string }[] = [
    {
        image: "/images/mommies-logo/rich.jpg",
        title: "FULLY LOADED",
        text: "The Sugar Mummy has to have a solid source of financial backing. She does not have to be a millionaire, but her financial resources have to be sufficient enough to pamper her Sugar boy. This is the first level of qualification to becoming a Sugar Mummy. For the first role of the Sugar Mummy is to provide for the financial needs of her Sugar boy."
    },
    {
        image: "/images/mommies-logo/gentle.jpg",
        title: "A TRUE GENTLEWOMEN",
        text: "She is chivalrous, respectful, accommodating, and caring. With security and good conversation flowing from a well-dressed gentlewomen, all a handsome Sugar Boy needs is a hand that offers a seat, someone who opens the door, stays by him side and treats him like a respectable young guy."
    },
    {
        image: "/images/mommies-logo/agreement.jpg",
        title: "TERMS OF AGREEMENT",
        text: `Every relationship in ${siteTitle} starts out with a discussion of their respective expectations. This is the part where sugar boys spell out their unique wants and needs before getting into a relationship. A relationship is formed when both parties reach an agreement.`
    },
    {
        image: "/images/mommies-logo/privacy.jpg",
        title: "HONEST & TRANSPARENT",
        text: `Any successful relationship would require honesty from both parties. Here at ${siteTitle}, we are strong believers in being clear with one another and in the relationship.`
    }
]

export const sugarMummies: {
    name: string,
    path: string,
    filename: string,
    address: string,
    version: number,
    content?: string
}[] = [
    {
        "path": "https://res.cloudinary.com/dtsgqaasr/image/upload/",
        "version": 1708209180,
        "filename": "v1708209180/Sugar%20Mummy%20Malaysia/3_uozj1q.jpg",
        "name": "Joyce",
        "address": "C135, Tingkat, 1, Lorong 10, Taman Sejati Indah, 08000 Sungai Petani, Kedah, Malaysia"
    },
    {
        "path": "https://res.cloudinary.com/dtsgqaasr/image/upload/",
        "version": 1708209180,
        "filename": "v1708209180/Sugar%20Mummy%20Malaysia/5_blilvn.jpg",
        "name": "Aisyah",
        "address": "C135, Tingkat, 1, Lorong 10, Taman Sejati Indah, 08000 Sungai Petani, Kedah, Malaysia"
    },
    {
        "path": "https://res.cloudinary.com/dtsgqaasr/image/upload/",
        "version": 1708209180,
        "filename": "v1708209180/Sugar%20Mummy%20Malaysia/9_jifw3z.jpg",
        "name": "Gwen",
        "address": "5, Jln Setia Tropika 3/8, Taman Setia Tropika, 81200 Johor Bahru, Johor, Malaysia"
    },
    {
        "path": "https://res.cloudinary.com/dtsgqaasr/image/upload/",
        "version": 1708209180,
        "filename": "v1708209180/Sugar%20Mummy%20Malaysia/2_mpoijo.jpg",
        "name": "Anaisha",
        "address": "Pt 2949, Kampung Pasir Pekan, 16250 Wakaf Bharu, Kelantan, Malaysia"
    },
    {
        "path": "https://res.cloudinary.com/dtsgqaasr/image/upload/",
        "version": 1708209179,
        "filename": "v1708209179/Sugar%20Mummy%20Malaysia/8_djpodd.jpg",
        "name": "Rose",
        "address": "2a, Jalan Pavitt Place, Tanjung Aru, 88100 Kota Kinabalu, Sabah, Malaysia"
    },
    {
        "path": "https://res.cloudinary.com/dtsgqaasr/image/upload/",
        "version": 1708209179,
        "filename": "v1708209179/Sugar%20Mummy%20Malaysia/4_ujjgyp.jpg",
        "name": "Nancy",
        "address": "Persiaran Lengkuas 2, Kampung Belachan, 10470 Tanjung Tokong, Pulau Pinang, Malaysia"
    },
    {
        "path": "https://res.cloudinary.com/dtsgqaasr/image/upload/",
        "version": 1708209179,
        "filename": "v1708209179/Sugar%20Mummy%20Malaysia/1_ykw9v5.jpg",
        "name": "Lisa",
        "address": "333, Jln Damansara, Bukit Damansara, 50490 Kuala Lumpur, Wilayah Persekutuan Kuala Lumpur, Malaysia"
    },
    {
        "path": "https://res.cloudinary.com/dtsgqaasr/image/upload/",
        "version": 1708209179,
        "filename": "v1708209179/Sugar%20Mummy%20Malaysia/7_tqsafl.jpg",
        "name": "Becky",
        "address": "24-2B, Jln Telawi 3, Bangsar, 59100 Kuala Lumpur, Wilayah Persekutuan Kuala Lumpur, Malaysia"
    },
    {
        "path": "https://res.cloudinary.com/dtsgqaasr/image/upload/",
        "version": 1708209179,
        "filename": "v1708209179/Sugar%20Mummy%20Malaysia/10_v3ew7t.jpg",
        "name": "Chen",
        "address": "57, Jalan Penaga, Bangsar, 59100 Kuala Lumpur, Wilayah Persekutuan Kuala Lumpur, Malaysia"
    },
    {
        "path": "https://res.cloudinary.com/dtsgqaasr/image/upload/",
        "version": 1708209178,
        "filename": "v1708209178/Sugar%20Mummy%20Malaysia/11_l33lky.jpg",
        "name": "Alisha",
        "address": "140-1, Jalan Toman 7, Kemayan Square, 70200 Seremban, Negeri Sembilan, Malaysia"
    },
    {
        "path": "https://res.cloudinary.com/dtsgqaasr/image/upload/",
        "version": 1708209178,
        "filename": "v1708209178/Sugar%20Mummy%20Malaysia/6_deha8s.jpg",
        "name": "Sophia",
        "address": "NUSARI AMAN 2, 839, Lrg Nusari Aman 3/5D, Bandar Sri Sendayan, 70990 Seremban, Negeri Sembilan, Malaysia"
    }
]

export const testimonials: {name: string, location: string, content: string, image: string}[]= [
    {
        image: '/images/boys/5.jpeg',
        name: "Wei Kang",
        location: "Perth, Western Australia",
        content: "I was delighted to get the best opportunity to make a side income from sugar mummy and all thanks to Miss Rose for getting me connected with sugar mummy after seeing some of their beautiful photos and profile. I am so glad now and you too can boss up to your own level by benefiting a lot of fun and money from them."
    },
    {
        image: '/images/boys/3.jpeg',
        name: "Abdul Lateef",
        location: "Bracalba, Brisbane, Queensland",
        content: "It still feels like a dream how I got connected with a sugar mummy in Australia finally through the help of this freelance and discreet hookup agency. I now meet with any mummy of my choice and make fun with them and benefit from a lot of money and gifts, i am happy and thankful for the help of this freelance agency."
    },
    {
        image: '/images/boys/6.jpeg',
        name: "Razali Bin Mohd",
        location: "Bradbury, Adelaide Hills, South Australia",
        content: "I now finally enjoyed best and happy moment of fun with rich sugar mummies i get connected to with the help of this freelance and discreet hookup agency, I came across the website and decided to give it a try and i was get connected after getting beautiful photos of some sugar mummy and decided to meet with one, i now benefit a lot of side income and fun pleasure."
    },
    {
        image: '/images/boys/4.jpeg',
        name: "Joseph Chin ",
        location: "Bradshaws Creek, Dorset, Tasmania",
        content: "My life story changed after few encounter with sugar mummy and i now benefits a lot of money, all thanks to this freelance hookup agency for giving me this life opportunity."
    },
    {
        image: '/images/boys/8.jpeg',
        name: "Aaron Kwan",
        location: "Break O'Day, Tasmania",
        content: "Are you vibrant in bed and have all it takes to make beautiful sugar mummy happy in bed and if you are experience and good in pleasuring women in bed, Freelance and discreet hookup agency is the right place for you to get connected with sugar mummy and start getting money and other benefits as side income in Australia today each time you meet for fn and sex."
    },
    {
        image: '/images/boys/2.jpeg',
        name: "David Kong Hoon",
        location: "Braddon, Canberra, Australian Capital Territory",
        content: "Am Terrence from Malaysia and i am very delighted to get rich sugar mummy in Australia after coming across freelance hookup agency who get me to some beautiful Eurasian, Caucasian and Asian sugar mummy and i get paid handsomely each time i meet and pleasure them in bed...Don't be left out."
    },
    {
        image: '/images/boys/1.jpeg',
        name: "Edmung Ah Chong",
        location: "Newcastle, New South Wales",
        content: "My financial status now changed after encountering some rich sugar mummy here in Australia and all thanks to Freelance and discreet hookup agency for this wonderful and beautiful opportunity."
    },
    {
        image: '/images/boys/7.jpeg',
        name: "Kar Chye Chuk",
        location: "Sydney New South Wales",
        content: "Today is the most happy day in my life as I earn side income from sugar mummy in Australia. Each time we meet for sex time in her bedroom, you can reach out to freelance and discreet hookup agencies today to get your link."
    }
]

export const rnd = (min: number = 0, max: number = Number.MAX_SAFE_INTEGER) => Math.floor(Math.random() * ((max - min) + 1)) + min;

export const rnd_version = (path: string, filename: string) => `${path}${rnd(0, 1) === 1 ? 'c_fill,w_100,h_100,ar_1:1,g_auto' : 'c_fill,w_100,h_100,ar_1:1'}/${filename}`