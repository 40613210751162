import React from 'react';
import {Container, Card, Row, Col} from "react-bootstrap";
import styled, {createGlobalStyle} from "styled-components";
import shuffle from 'shuffle-array'
import {rnd, rnd_version, siteTitle, sugarMummies, testimonials, W_I_M_T_B_A_S_D} from "../addon/utils";
import {Link} from "react-router-dom";


export const Content = () => {


    return <BoardDiv>
        <GlobalStyles/>
        <AboutDiv>
            <TitleH2>What is a Sugar Relationship?</TitleH2>
            <p>A Sugar Relationship is an arrangement where like-minded, consenting adults agree to terms that mutually
                benefit both parties. These terms include but are not limited to companionship, mentorship, financial
                and emotional support.</p>
        </AboutDiv>


        <div style={{background: "#F9F9F9"}}>
            <SugarMummyDiv fluid={"lg"}>
                <NowrapRow>
                    <Col lg={4} md={4} sm={12}>
                        <h5 style={{fontWeight: "bolder", textTransform: "uppercase", paddingBottom: 7}}>Our Top Sugar
                            mummies</h5>
                        <Row>
                            {
                                shuffle(sugarMummies).map((src, index) => {
                                    if (index >= 8) return null;
                                    return <Col key={rnd().toString()} lg={3} md={3} sm={3} xs={3}
                                                style={index < 4 ? {marginBottom: 15} : undefined}>
                                        <ImgCrop src={rnd_version(src.path, src.filename)}/>
                                    </Col>
                                })
                            }
                        </Row>
                        <BrowseLink to={"/sugar-mummies"}>
                            Browse Sugar Mummies
                            <svg style={{width: 13, marginLeft: 10}} xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 512 512" fill={"currentcolor"}>
                                <path
                                    d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z"/>
                            </svg></BrowseLink>
                    </Col>
                    <Col lg={8} md={8} sm={12}>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <Perks><span>Sugar Mummy Perks</span></Perks>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6} md={6} sm={12}>
                                <PerksH4>IDEAL RELATIONSHIPS</PerksH4>
                                <PerksP>Build and grow modern relationships with your own terms and conditions</PerksP>
                            </Col>

                            <Col lg={6} md={6} sm={12}>
                                <PerksH4>NO STRINGS ATTACHED</PerksH4>
                                <PerksP>No dramas, no restrictions and no commitments</PerksP>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={6} md={6} sm={12}>
                                <PerksH4>DISCREET IDENTITY</PerksH4>
                                <PerksP>Remain anonymous as you wish</PerksP>
                            </Col>

                            <Col lg={6} md={6} sm={12}>
                                <PerksH4>FULFILL YOUR FANTASIES</PerksH4>
                                <PerksP>Mingle and network to your advantage</PerksP>
                            </Col>
                        </Row>
                    </Col>
                </NowrapRow>
                <TitleH2 $textAlign={"left"}>Experience a modern way of sugar dating</TitleH2>
                <PerksP>At {siteTitle}, our goal is to provide you with the best sugar dating experience. Your
                    satisfaction and discretion will always remain our top priority.<br/><br/>Founded in 2019, we are
                    one of the largest sugar mummy dating platform in Asia. Now with over 12 thousand members in
                    malaysia and across the globe, experience the honest relationship of your dreams with just the tap
                    of a button.<br/><br/>Begin by clicking the join button or message Mrs. Rose on WhatsApp. send a
                    short description of yourself. We'll match you with one of our top mummies and let them know you can
                    be their ideal sugar partner.</PerksP>
            </SugarMummyDiv>
        </div>


        <GradientDiv>
            <div></div>
            <SugarMummiesDiv fluid={"lg"} $paddingBottom={20} $paddingTop={20}>
                <Row>
                    <Col lg={6} md={6} xs={12}>
                        <span>WHAT IT MEANS TO BE A</span>
                        <p>Sugar Mummy</p>
                    </Col>
                </Row>
            </SugarMummiesDiv>
        </GradientDiv>

        <SugarMummiesDiv fluid={"lg"} $paddingTop={5}>
            {
                W_I_M_T_B_A_S_D.map((value, index) => {
                    return <CardDiv key={rnd().toString()}>
                        <div className={"number-side"}>{index + 1}</div>
                        <div className={"middle-side"}>
                            <p className={"title"}>{value.title}</p>
                            <p>{value.text}</p>
                        </div>
                        <div className={"right-side"}>
                            <img src={value.image ?? ""} alt={''}/>
                        </div>
                    </CardDiv>
                })
            }

        </SugarMummiesDiv>

        <div style={{background: "#F9F9F9"}}>
            <SugarMummyDiv fluid={"lg"}>
                <Row>
                    <Col md={12} xs={12}>
                        {/*<TitleH2 $textAlign={"left"}>Experience a modern way of sugar dating</TitleH2>*/}
                        <figure>
                            <Blockquote className="blockquote">
                                <p>Sugar Mummy</p>
                            </Blockquote>
                            <figcaption className="blockquote-footer">
                                Composed and knowledgeable, a sugar mummy is a gentlewoman who is comfortable with his
                                financial reservoir and is of a respectable degree. He takes it upon herself to provide
                                her sugar boy with not only luxurious gifts, but also the means to be a successful
                                individual.
                            </figcaption>
                        </figure>
                    </Col>
                    <Col md={12} xs={12}>
                        <PerksP2 style={{opacity: 1, color: "inherit"}}>
                            Sugar mummy is a colloquial term used to describe a wealthy woman financially supporting a
                            younger man or "Sugar boy." This relationship can take many forms, but it typically
                            involves the Sugar mummy providing gifts, money, and other financial support to the Sugar
                            boy in exchange for companionship, intimacy, or other forms of attention.
                            <br/>
                            <br/>
                            The term "Sugar mummy" is believed to have originated in the early 1900s when wealthy women
                            would provide financial support to young, attractive men in exchange for companionship and
                            sometimes intimate favors. Today, the concept of a Sugar mummy has evolved and can involve a
                            wide range of relationships, from casual dating to long-term arrangements.
                            <br/>
                            <br/>
                            Sugar mummies can be found in all walks of life, from successful business people to
                            celebrities. They may be single, divorced, or even married. They may be looking for a fun,
                            no-strings-attached relationship or seek a more serious, long-term arrangement. In terms of
                            personality & character, Sugar mummies are a textbook example of refined gentlewomen &
                            chivalrous towards ladies; they know when to hold the door, what to say to make someone feel
                            special & always pick up the check. Beyond that, a Sugar mummy also appreciates being able
                            to share their wisdom & life experience with an attentive Sugar boy.
                            <br/>
                            <br/>
                            A Sugar mummy may be fed up or bored with work & home obligations. Our websites provide
                            means for them to take a time-out on the conventional and enjoy a
                            little excitement. While sugar relationships can be beneficial to both parties, it's
                            important to be upfront and honest about your expectations when a Sugar mummy meets a Sugar
                            boy. It's also important to use caution when meeting people online and to be mindful of the
                            potential risks.
                        </PerksP2>
                    </Col>
                </Row>
            </SugarMummyDiv>
        </div>
        {/*

        <div style={{background: "#252525"}}>
            <SugarMummyDiv fluid={"lg"}>
                <Row>
                    <Col md={12} xs={12}><TitleH2 $textAlign={"left"} style={{color: "#fff"}}>Experience a modern way of
                        sugar dating</TitleH2></Col>
                    <Col md={12} xs={12}>
                        <PerksP2 style={{color: "#fff"}}>
                            At {siteTitle}, our goal is to provide you with the best sugar dating experience. Your
                            satisfaction and discretion will always remain our top priority.
                            <br/>
                            <br/>
                            Founded in 2019, we are one of the largest sugar mummy dating platform in Asia. Now with
                            over 12
                            thousand members in malaysia and across the globe, experience the honest relationship of
                            your dreams with just the tap of a button.
                            <br/>
                            <br/>
                            Begin by clicking the join button or message Mrs. Rose on WhatsApp. send a short description
                            of yourself. We'll match you with one of our top mummies and let them know you can be their
                            ideal sugar partner.
                        </PerksP2>
                    </Col>
                </Row>
            </SugarMummyDiv>
        </div>

*/}

        <SugarMummyDiv fluid={"lg"}>
            <RowDiv>
                {
                    shuffle(testimonials).map((value, index) => {
                        if (index >= 3) return null;
                        // @ts-ignore
                       // const location = siteTitle //value.location.includes(",") ? value.location.split(",").at(-1).trim() : value.location
                        return <Col xs={12}>
                            <div>
                                <img src={value.image} className="img-responsive img-circle" alt={""}/>
                            </div>
                            <blockquote className="blockquote">
                                <p className="m-b-0">{value.content}</p>
                                <footer className="blockquote-footer">
                                    #{value.name},
                                    <cite title="Source Title">{siteTitle}</cite>
                                </footer>
                            </blockquote>
                        </Col>
                    })
                }
            </RowDiv>
        </SugarMummyDiv>
    </BoardDiv>
}


const AboutDiv = styled(Container)`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0 70px;

    p {
        font-size: 15px;
        font-weight: 400;
        font-style: normal;
        line-height: 1.46667;
        color: rgb(70, 70, 70);
        font-variant: normal;
        margin: 20px 0 10px;
        max-width: 657px;
    }
`

const TitleH2 = styled.h2<{
    $textAlign?: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent' | 'justify-all';
    $marginTop?: number;
    $marginBottom?: number
}>`
    margin-top: ${props => props.$marginTop || 20}px;
    margin-bottom: ${props => props.$marginBottom || 10}px;;
    font-size: 28px;
    font-weight: bold;
    text-align: ${props => props.$textAlign || "center"};
    max-width: 657px;
`

const Blockquote = styled.blockquote`
    margin-top: -20px;
    font-size: 28px;
    font-weight: bold;
    text-align: left;
`

const SugarMummyDiv = styled(Container)`
    padding: 90px 0 70px;

    @media (min-width: 1100px) {
        max-width: 960px;
    }
`

const ImgCrop = styled.img`
    width: 100%;
    height: auto;
    border-radius: 5px;
`


const Perks = styled.h3`
    text-align: left;
    font-weight: 300;
    font-size: 28px;

    @media screen and (max-width: 768px) {
        margin-top: 20px;
    }
`

const PerksH4 = styled.h4`
    margin-top: 15px;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 10px;
    line-height: 1.1;
`

const PerksP = styled.p`
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
`

const PerksP2 = styled.p`
    font-size: 15px;
    font-weight: 400;
    font-style: normal;
    line-height: 1.46667;
    color: #ffffff;
    font-variant: normal;
    margin: 20px 0 10px;
    opacity: .7;
`

const NowrapRow = styled(Row)`
    @media screen and (min-width: 767px) {
        gap: 30px !important;
        flex-wrap: nowrap !important;
    }
`


const SugarMummiesDiv = styled(Container)<{ $paddingTop?: number; $paddingBottom?: number }>`
    padding: ${props => props.$paddingTop || 70}px 0 ${props => props.$paddingBottom || 70}px;

    @media (min-width: 1100px) {
        max-width: 960px;
    }
`

const GradientDiv = styled.div`
    position: relative;
    min-height: 130px;
    overflow: hidden;

    & > div:first-child {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: transparent;
        background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 28%, rgba(255, 255, 255, 1) 50%, rgba(0, 212, 255, 0) 100%);
    }

    & > div:nth-child(2) {
        position: relative;
        z-index: 2;
    }

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        object-fit: cover;
        background: url(/images/mommies-logo/landscape.jpg) no-repeat 100% 50%;
    }

    span {
        text-align: left;
        color: #710D0D;
        font-size: 30px;
        font-weight: 300;
    }

    p {
        margin-top: -14px;
        font-size: 68px;
        color: #444444;
        font-family: 'Georgia';
    }

    @media screen and (max-width: 768px) {
        span {
            font-size: 25px;
        }

        p {
            margin-top: -10px;
            font-size: 40px;
        }
    }
`

const CardDiv = styled(Card)`
    display: table;
    //border: 1px solid #ccc;
    background-color: #fff;
    padding: 30px 24px;
    margin-top: 50px;

    .number-side {
        vertical-align: middle;
        border-right: 1px solid #ccc;
        color: #710d0d;
        font-weight: 700;
        font-size: 30px;
        padding-right: 18px;
        display: table-cell;
    }

    .middle-side {
        padding: 0 24px;
        display: table-cell;
        vertical-align: middle;
    }

    .right-side {
        display: table-cell;
        vertical-align: middle;
        width: 160px;
    }

    img {
        display: block;
        max-width: 100%;
        height: auto;
        border-radius: 16px;
    }

    .content-inside {
        font-size: 16px;
        line-height: 1.65;
        margin: 0 0 10px;
    }

    .title {
        font-weight: bold;
        color: #710D0D;
        font-size: 15px;
        text-transform: uppercase;
        margin: 0 0 10px;
    }

    @media screen and (max-width: 768px) {
        .right-side {
            display: none !important;
        }
    }
`

const BoardDiv = styled.div`
    width: 100%;
    @media screen and (max-width: 768px) {
        padding: 0 15px;
    }
`

const BrowseLink = styled(Link)`
    display: inline-block;
    margin-top: 1px;
    text-decoration: none;
    color: #cd7989;

    &:hover {
        text-decoration: underline;
        font-weight: 700;
    }
`

const GlobalStyles = createGlobalStyle`

`

const RowDiv = styled(Row)`
    & > div > div {
        text-align: center;
    }

    & > div img {
        margin: 0 auto;
        width: 150px;
        border-radius: 50%;
        height: 150px;
        object-fit: cover;
        object-position: center;
    }

    & > div blockquote {
        border-left: none;
        font-style: italic;
        margin-top: 20px;
    }

    & > div blockquote p {
        font-size: 14px;
        color: #333;
    }
    
    @media screen and (min-width: 767px){
        display: flex;
        gap: 30px;
        justify-content: space-between;
        & > div{
            width: calc(33% - 20px);
        }
    }
`

