import React, {useEffect, useRef, useState} from 'react';
import {Container, Card, Row, Col} from "react-bootstrap";
import styled, {createGlobalStyle} from "styled-components";
import shuffle from 'shuffle-array'
import {rnd, sugarMummies, telegramUrl} from "../addon/utils";


export const Content = () => {
    const row = useRef<HTMLDivElement>(null)
    const [imageSize, setImageSize] = useState<number | string>(0)

    const resizeCard = () => {
        if (row.current) {
            if (row.current.clientWidth >= 950) setImageSize(((row.current.clientWidth - 60) / 5))
            else if (row.current.clientWidth >= 720) setImageSize(((row.current.clientWidth - 60) / 4))
            else if (row.current.clientWidth >= 540) setImageSize(((row.current.clientWidth - 60) / 3))
            else if (row.current.clientWidth >= 360) setImageSize(((row.current.clientWidth - 60) / 2))
            else setImageSize("100%")
        }
    }

    useEffect(() => {
        resizeCard()
        window.addEventListener('resize', function () {
            resizeCard()
        })
    }, [])

    return <BoardDiv>
        <GlobalCss />
        <AboutDiv style={{paddingBottom: 0}}>
            <TitleH2 style={{display: 'none'}}>What is a Sugar Relationship?</TitleH2>
            <p style={{display: 'none'}}>A Sugar Relationship is an arrangement where like-minded, consenting adults agree to terms that mutually
                benefit both parties. These terms include but are not limited to companionship, mentorship, financial
                and emotional support.</p>
        </AboutDiv>


        <SugarMummiesDiv $paddingTop={5}>
            <Row ref={row}>
                {
                    shuffle(sugarMummies).map((value, index) => {
                        if (index >= 10) return null;
                        return <Col key={rnd().toString()} style={{width: imageSize, minWidth: imageSize}}>
                            <CardDiv>
                                <img src={rnd_version(value.path, value.filename)} alt={''}/>
                                <div className={"cardBlock"}>
                                    <p className={"cardText"}>{value.name.toUpperCase()}</p>
                                    <p className={"cardTitle"}>{`${value.address.split(",").at(-2)?.trim()}, ${value.address.split(",").at(-1)?.trim()}`}</p>
                                </div>
                            </CardDiv>
                        </Col>
                    })
                }
            </Row>
            <Row>
                <Col md={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 10, marginTop: 10}}>
                    <span style={{textAlign:"center",fontWeight:"bold", letterSpacing: 2}}>1-10</span>
                    <span onClick={()=>{
                        const a = document.createElement('a');
                        a.href = telegramUrl
                        document.body.appendChild(a)
                        a.target = "_blank"
                        a.click()
                        a.remove()
                    }} className={"btn btn-border"}>Next</span>
                </Col>
            </Row>
        </SugarMummiesDiv>
    </BoardDiv>
}

function rnd_version(path: string, filename: string) {
    return `${path}${rnd(0, 1) === 1 ? 'c_fill,w_500,h_500,ar_1:1,g_auto' : 'c_fill,w_500,h_500,ar_1:1'}/${filename}`
}


const AboutDiv = styled(Container)`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0 70px;

    p {
        font-size: 15px;
        font-weight: 400;
        font-style: normal;
        line-height: 1.46667;
        color: rgb(70, 70, 70);
        font-variant: normal;
        margin: 20px 0 10px;
        max-width: 657px;
    }
`

const TitleH2 = styled.h2<{
    $textAlign?: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent' | 'justify-all';
    $marginTop?: number;
    $marginBottom?: number
}>`
    margin-top: ${props => props.$marginTop || 20}px;
    margin-bottom: ${props => props.$marginBottom || 10}px;;
    font-size: 28px;
    font-weight: bold;
    text-align: ${props => props.$textAlign || "center"};
    max-width: 657px;
`


const SugarMummiesDiv = styled(Container)<{ $paddingTop?: number; $paddingBottom?: number }>`
    padding: ${props => props.$paddingTop || 70}px 0 ${props => props.$paddingBottom || 70}px;


    @media screen and (min-width: 1200px) {
        max-width: 1170px;
    }
  
`


const CardDiv = styled(Card)`
    border: 1px solid #d6d6d6;
    background-color: #fff;
    max-width: 350px;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 10px;

    img {
        width: 100%;
        height: auto;
    }

    .cardBlock {
        padding: 10px;
        text-align: center;
    }

    .cardText {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .cardTitle {
        font-weight: 700;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0 0 10px;
    }

`

const GlobalCss = createGlobalStyle`
    .btn-border{
        background-color: #fff!important;
        background-repeat: repeat-x;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff");
        background-image: -khtml-gradient(linear,left top,left bottom,from(#ffffff),to(#ffffff));
        background-image: -moz-linear-gradient(top,#ffffff,#ffffff);
        background-image: -ms-linear-gradient(top,#ffffff,#ffffff);
        background-image: -webkit-gradient(linear,left top,left bottom,color-stop(0%,#ffffff),color-stop(100%,#ffffff));
        background-image: -webkit-linear-gradient(top,#ffffff,#ffffff);
        background-image: -o-linear-gradient(top,#ffffff,#ffffff);
        background-image: linear-gradient(#ffffff,#ffffff);
        border-color: #989898 #989898 #999;
        color: #333!important;
        text-shadow: 0 1px 1px transparent;
        -webkit-font-smoothing: antialiased;
        border-radius: 50px;
    }


    .btn-border:hover {
        border-color: #989898 #989898 #999 !important;
    }

    .btn-border-none:focus {
        outline: none!important
    }
`

const BoardDiv = styled.div`
    width: 100%;
    @media screen and (max-width: 768px) {
        padding: 0 15px;
    }
`



