import React from "react";
import {Content} from "./content";
import {Menu} from "../addon/menu";
import {createGlobalStyle} from "styled-components"
import {Helmet} from "react-helmet";
import {Footer} from "../addon/footer";

export const SugarMummies = () => {

    return <React.Fragment>
        <Helmet>
            <title>Mummies - Sugar Mummy Malaysia</title>
            <meta
                name="description"
                content="A Sugar Relationship is an arrangement where like-minded, consenting adults agree to terms that mutually benefit both parties. These terms include but are not limited to companionship, mentorship, financial and emotional support."
            />
            <link rel="apple-touch-icon" sizes="57x57" href="/images/icon/apple-icon-57x57.png"/>
            <link rel="apple-touch-icon" sizes="60x60" href="/images/icon/apple-icon-60x60.png"/>
            <link rel="apple-touch-icon" sizes="72x72" href="/images/icon/apple-icon-72x72.png"/>
            <link rel="apple-touch-icon" sizes="76x76" href="/images/icon/apple-icon-76x76.png"/>
            <link rel="apple-touch-icon" sizes="114x114" href="/images/icon/apple-icon-114x114.png"/>
            <link rel="apple-touch-icon" sizes="120x120" href="/images/icon/apple-icon-120x120.png"/>
            <link rel="apple-touch-icon" sizes="144x144" href="/images/icon/apple-icon-144x144.png"/>
            <link rel="apple-touch-icon" sizes="152x152" href="/images/icon/apple-icon-152x152.png"/>
            <link rel="apple-touch-icon" sizes="180x180" href="/images/icon/apple-icon-180x180.png"/>
            <link rel="icon" type="image/png" sizes="192x192" href="/images/icon/android-icon-192x192.png"/>
            <link rel="icon" type="image/png" sizes="32x32" href="/images/icon/favicon-32x32.png"/>
            <link rel="icon" type="image/png" sizes="96x96" href="/images/icon/favicon-96x96.png"/>
            <link rel="icon" type="image/png" sizes="16x16" href="/images/icon/favicon-16x16.png"/>
            <link rel="manifest" href="/images/icon/manifest.json"/>
            <meta name="msapplication-TileColor" content="#ffffff"/>
            <meta name="msapplication-TileImage" content="/images/icon/ms-icon-144x144.png"/>
            <meta name="theme-color" content="#ffffff"/>
        </Helmet>
        <Global/>
        <div>
            <Menu notHome={true}/>
            <Content/>
        </div>
        <Footer/>
    </React.Fragment>
}

const Global = createGlobalStyle`
    body {
        background: #f3f2f1 !important;
        padding-top: 100px;
    }
    
`