import React, {useEffect, useState} from "react";
import {Col, Container, Row, Navbar, Nav} from "react-bootstrap";
import styled from "styled-components";
import { Link } from 'react-router-dom';


export const Menu = (props: { notHome?: boolean }) => {
    const [top, setTop] = useState<number>(topPosition())
    useEffect(() => {
        window.addEventListener('scroll', (evt) => {
            if (!props.notHome) {
                setTop(topPosition())
            }
        })
    }, [props.notHome])
    return <ContainerDiv $top={top} $notHome={props.notHome}>
        <Container>
            <Row>
                <Col md={3} lg={2} sm={6} className={"hide-mobile"}>
                    <Link className="logo" aria-label="logo" to="/"
                       style={{backgroundImage: "url(/images/logo.svg)"}}>
                    </Link>
                </Col>
                <Col md={9} lg={"auto"} sm={6}>

                    <Navbar bg="light" expand={"lg"} className={"mobile"}>
                        <Navbar.Brand as={Link} to="/"></Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="mr-auto">
                                <Nav.Link as={Link} to="/sugar-mummies" className={"sugar-mummies-mobile"}>Sugar Mummies</Nav.Link>
                                <Nav.Link as={Link} to="/contact-us" className={"contact-us-mobile"}>Contact Us</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>

                    <div className="nav disktop hide-mobile">
                        <div className="pc pcShow">
                            <Link to="/sugar-mummies" className="signIn goSignIn">Sugar Mummies</Link>
                            <Link to="/contact-us" className="signUp btn">Contact Us</Link>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </ContainerDiv>
}

const topPosition = () => window.pageYOffset


const ContainerDiv = styled.div<{ $top?: number, $notHome?: boolean }>`
    position: ${props => (props.$top && props.$top > 100 ? "fixed" : "absolute")};
    background: ${props => ((props.$top && props.$top > 100 )|| props.$notHome ? "#ffffff" : "initial")};
    box-shadow: ${props => ((props.$top && props.$top > 100)|| props.$notHome ? "0 1px 6px 0 rgba(0,0,0,0.2);" : "none")};
    
    top: 0;
    height: 100px;
    width: 100%;
    left: 0;
    z-index: ${props => (props.$top && props.$top > 100 ? 1000 : 1)};
    box-sizing: border-box;
    display: flex;
    align-items: center;
    transition: all .5s ease-in-out;
    
    .container > div {
        justify-content: space-between;
        align-items: center;
    }

    .container > div .nav > div {
        display: flex;
        align-items: center;
    }

    .logo {
        width: 308px;
        height: 58px;
        cursor: pointer;
        display: flex;
        align-items: center;
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-image: url("/images/logo.svg");
        text-decoration: none;
        outline: 0;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        color: #1b1b1b;
    }

    .signUp {
        text-decoration: none;
        outline: 0;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

        padding: 0 12px;
        display: inline-block;
        font-weight: 400;
        letter-spacing: 1px;
        text-transform: uppercase;

        border-radius: 4px;
        text-align: center;
        margin-left: 21px;
        color: #fff;
        cursor: pointer;

        width: 140px;
        height: 40px;
        background: linear-gradient(90deg, #e46c8a, #ef9b75);
        line-height: 40px;
    }

    .signIn {
        padding-left: 42px;
        color: #ed698c;
        display: inline-block;
        font-weight: 400;
        line-height: 40px;
        letter-spacing: 1px;
        text-transform: uppercase;
        text-decoration: none;
        outline: 0;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        font-size: 15px;
    }
    
    button[aria-controls=basic-navbar-nav]{
        outline: none !important;
        box-shadow: none !important;
        margin-right: 15px;
    }

    @media screen and (min-width: 767px) {
        .mobile{
            display: none;
        }
    }
    @media screen and (max-width: 768px) {
        min-height: 80px;
        height: auto !important;
        display: flex;
        align-items: flex-start;
        padding-top: 17px;
        z-index: 100;
        background:  #ffffff !important;
        
        .desktop, .hide-mobile{
            display: none;
        }

        .bg-light{
            background: transparent !important;
        }
        
        .sugar-mummies-mobile{
            margin-top: 30px;
            margin-left: 21px;
            color: #ed698c;
            display: inline-block;
            font-weight: 400;
            line-height: 40px;
            letter-spacing: 1px;
            text-transform: uppercase;
            text-decoration: none;
            outline: 0;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            font-size: 15px;
            width: calc(100% - 42px);
            text-align: center;
            
        }
        .contact-us-mobile{
            text-decoration: none;
            outline: 0;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

            padding: 0 12px;
            display: inline-block;
            font-weight: 400;
            letter-spacing: 1px;
            text-transform: uppercase;

            border-radius: 4px;
            text-align: center;
            margin-left: 21px;
            color: #fff;
            cursor: pointer;

            width: calc(100% - 42px);
            height: 40px;
            background: linear-gradient(90deg, #e46c8a, #ef9b75);
            line-height: 40px;
            margin-bottom: 15px;
        }
        
        .navbar-brand{
            width: 208px;
            height: 36px;
            cursor: pointer;
            display: flex;
            align-items: center;
            background-size: auto 100%;
            background-repeat: no-repeat;
            background-image: url("/images/logo.svg");
            text-decoration: none;
            outline: 0;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            color: #1b1b1b;
            margin-left: 15px;
        }
    }
    
`