import React from "react";
import {Col, Row} from "react-bootstrap";
import styled from "styled-components";
import {Link} from "react-router-dom";
import {siteName, siteTitle, telegramUrl, whatsappUrl} from "./utils";

export const Footer = ()=>{
    return <React.Fragment>
        <Container>
           <div>
               <Row className={"gap-lg-2"}>
                   <Col>
                       <p>Copyright © {new Date().getFullYear()} <Link to={"/"}>{siteTitle} ({siteName})</Link>, Inc. All Rights Reserved.</p>
                   </Col>
                   <Col><p><Link to={whatsappUrl}>WhatsApp</Link></p></Col>
                   <Col><p><Link to={telegramUrl}>Telegram</Link></p></Col>
                   <Col><p><Link to={"/contact-us"}>Contact Us</Link></p></Col>
                   <Col><p><Link to={"/sugar-mummies"}>Mummies</Link></p></Col>
               </Row>
               <div className={"details"}>
                   {siteTitle} is an industry leader in luxury sugar dating where young men and rich women connect, meet and grow honest relationships.
               </div>
           </div>
        </Container>
    </React.Fragment>
}




const Container = styled.div`
    border-top: 1px solid #E6E6E6;
    width: 100%;
    background-color: #fff !important;
    margin-top: 50px !important;
    padding: 30px 58px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    .details{
        color: #7d7a7a;
    }
    
    a{
        color: #e46c8a;
        text-decoration: none;
    }

    & > div{
        width: 100%;
    }

    @media screen and (min-width: 900px) {
        & > div > div{
            display: flex;
            gap: 30px;
            justify-content: center;
        }
        & > div > div > div{
            display: contents;
        }
        p{
            white-space: nowrap;
        }
    }
    
    @media screen and (min-width: 1200px) {
        & > div{
            max-width: 1170px;
        }
    }

`