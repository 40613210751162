import React from 'react';
// import { createGlobalStyle } from "styled-components"
// import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import {Home} from "./components/home";
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import {SugarMummies} from "./components/sugar-mummies";
import {ContactUs} from "./components/contact-us";



const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/sugar-mummies",
    element: <SugarMummies />
  },
  {
    path: "/contact-us",
    element: <ContactUs />
  },
]);

function App() {
  return <RouterProvider router={router} />
}

export default App;

